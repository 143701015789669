<template>
  <div>
    <b-overlay
        :show="state.loading"
        rounded="sm"
    >
      <auth-modal
          :fino-datas="finoDatas"
          :auth-modal="authModal"
          @hidden="authModal=false"
          @handleAuth="changeAuth($event)"
          :national-id="nationalID"
          :image="image"
      />
      <bank-modal
          :datas="finoBankDatas"
          :userInfo="userData"
          :bank-modal="bankModal"
          @hidden="bankModal=false"
          @handleAuth="changeBankStatus($event)"
          :national-id="nationalID"
          :image="image"
      />
      <!-- Alert: No item found -->
      <b-alert v-if="isNaN(Number(id))"
               variant="danger"
               :show="true"
      >
        <h4 class="alert-heading">
          خطا در برقراری ارتباط
        </h4>
        <div class="alert-body">
          کاربری با این شماره پیدا نشد. برای دیدن کاربران به بخش
          <b-link
              class="alert-link"
              :to="{ name: 'Accounts'}"
          >
            لیست کاربران
          </b-link>
          مراجعه فرمایید
        </div>
      </b-alert>

      <template v-else>
        <template>
          <!-- First Row -->
          <b-row>
            <b-col
                cols="12"
                xl="9"
                lg="8"
                md="7"
            >
              <user-view-user-info-card
                  :user-data="userData"
                  @change-status="changeStatus"
                  @change-auth="changeAuthModal"
                  @force-auth="forceAuthModal"
                  @edit="sendData"
                  v-if="$havePermission('DETAILS_USERS')"
              />
            </b-col>
            <b-col
                cols="12"
                md="5"
                xl="3"
                lg="4"
            >
              <user-view-user-plan-card
                  :level="level"
                  :perv-level="pervLevel"
                  class="position-sticky"
                  style="top:100px"
              />
            </b-col>
            <b-col>
              <bank-accounts
                  :bankAccounts="userData.bankAccounts"
                  :auth="userData.authenticationType"
                  :check="check"
                  :key="randomNumber"
                  @checkAccount="changeBank($event)"
                  v-if="$havePermission('DETAILS_USERS')"
              />
            </b-col>
          </b-row>
        </template>

        <user-wallets :customer="id" v-if="$havePermission('LIST_WALLET')"/>

        <accounts v-if="$havePermission('LIST_USERS')"/>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import Accounts from "@/views/UserAccounts/Accounts";
import UserViewUserInfoCard from "@/views/UserAccounts/UserViewUserInfoCard";
import UserViewUserPlanCard from "@/views/UserAccounts/UserViewUserPlanCard";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BOverlay,
} from 'bootstrap-vue'
import UserWallets from "@/views/UserAccounts/userWallets";
import AuthModal from "@/views/UserAccounts/AuthModal";
import BankAccounts from "@/views/UserAccounts/BankAccounts";
import BankModal from "@/views/UserAccounts/BankModal";

export default {
  name: "showAccount",
  components: {
    BankModal,
    BankAccounts,
    AuthModal,
    UserWallets,
    UserViewUserPlanCard,
    UserViewUserInfoCard,
    Accounts,
    BRow,
    BCol,
    BAlert,
    BLink,
    BOverlay
  },
  props: ['id'],

  data() {
    return {
      userData: {
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        isActive: true,
        authenticationType: '',
        address: '',
        telephone: '',
        mobileNumber: '',
        totalTradesAmount: 0,
        totalTradesVolume: 0,
        birthday: '',
        nationalCode: 0,
        bankAccounts: []
      },
      finoDatas: {
        fullname: '',
        percent: 0,
      },
      finoBankDatas: {
        bankAccountDTO: {
          bankName: "",
          cardNumber: "",
          id: 0,
          shebaCode: "",
          status: "",
        },
        finoTechResponseOfCardToIbanDTO: {
          bankName: "",
          card: "",
          deposit: "",
          depositDescription: true,
          depositOwners: [
            {
              firstName: "",
              lastName: ""
            }
          ],
          iban: "",
        }
      },
      url: '',
      nationalID: '',
      image: require('@/assets/images/portrait/authPicture.png'),
      authModal: false,
      bankModal: false,
      check: [],
      randomNumber: 0,
      level: {},
      pervLevel: {},
    }
  },

  methods: {
    changeStatus() {
      this.$swal({
        title: 'آیا از تغییر وضعیت کاربر مطمئن هستید؟',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'بله',
        cancelButtonText: 'خیر',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          console.warn('change active')
          this.state.loading = true
          const address = '/users/' + (this.userData.isActive ? 'de' : '') + 'activate/' + this.userData.id
          const res = await this.$axios.post(this.url + address)

          !res.data.message.includes('activated')
          await this.getData(this.currentPage, this.perPage)

          this.$swal({
            icon: 'success',
            title: this.userData.isActive ? 'کاربر فعال شد' : 'کاربر غیر فعال شد',
            confirmButtonText: 'تایید',
            // text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    async changeBankStatus(e) {
      this.state.loading = true

      const res = await this.$axios.post(this.url + '/finotech/verify-bankaccount', {
        bankAccountId: e[0],
        isVerified: e[1],
      }).catch(() => {
      })

      for (let index in this.userData.bankAccounts) {
        this.check[index] = false
      }

      if (res) {
        this.$swal({
          icon: e[1] ? 'success' : 'warning',
          title: e[1] ? 'حساب تایید شد' : 'حساب تایید نشد',
          confirmButtonText: 'تایید',
          // text: 'Your file has been deleted.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })

        this.getData()
      }

    },
    async changeBank(e) {
      this.check[e] = true
      this.randomNumber = Math.random()
      const res = await this.$axios(this.url +'/finotech/validate-bankaccount', {
        params: {
          customerId: this.id,
          bankAccountId: this.userData.bankAccounts[e].id
        }
      })
      this.finoBankDatas = res.data.baseDTO
      // this.image = 'data:image/jpeg;base64,' + res.data.baseDTO.authenticationImage
      this.image = res.data.baseDTO.authenticationImage
      this.check[e] = false
      this.randomNumber = Math.random()
      this.bankModal = true
    },
    async changeAuthModal() {
      this.state.loading = true
      const res = await this.$axios(this.url + '/finotech/check-validation', {
        params: {
          email: this.userData.email
        }
      })

      this.finoDatas.fullName = res.data.baseDTO.fullName
      this.finoDatas.percent = Number(res.data.baseDTO.fullNameSimilarity)
      this.finoDatas.live = res.data.baseDTO.deathStatus
      this.nationalID = res.data.baseDTO.nationalCode
      // this.image = 'data:image/jpeg;base64,' + res.data.baseDTO.authorizationImage
      this.image = res.data.baseDTO.authorizationImage

      this.authModal = true
    },
    async forceAuthModal() {

      const res = await this.$swal({
        icon: 'warning',
        title: 'آیا از تایید حساب مطمئن هستید؟',
        showCancelButton: true,
        confirmButtonText: 'تایید حساب',
        cancelButtonText: 'لغو',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-secondary ml-1',
        },
      })
      if (res.isConfirmed) {
        await this.$axios.post(this.url + '/users/force-authorize/' + this.userData.id)
        this.$swal({
          icon: 'success',
          title: 'حساب تایید شد',
          confirmButtonText: 'متوجه شدم',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        this.getData()
      }
    },
    async changeAuth(e) {
      this.state.loading = true

      await this.$axios.post(this.url + '/finotech/check-validation', {
        email: this.userData.email,
        isVerified: e,
      })

      this.$swal({
        icon: e ? 'success' : 'warning',
        title: e ? 'کاربر تایید شد' : 'کاربر تایید نشد',
        confirmButtonText: 'تایید',
        // text: 'Your file has been deleted.',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })

      this.getData()
    },
    async sendData() {
      this.state.loading = true

      let FD = new FormData()
      delete this.userData.file
      for (let index in this.userData) {
        console.log('aaaa', index, this.userData[index])
        FD.append(index, this.userData[index])
      }

      const res = await this.$axios.post(this.url + '/users/details/' + this.id, FD, {headers: {enctype: 'multipart/form-data'}})
      if (res)
        await this.getData()
    },
    async getData() {
      this.state.loading = true

      const res = await this.$axios(this.url + '/users/details/' + this.id)

      this.userData = res.data.baseDTO

      for (let index in this.userData.bankAccounts) {
        this.check[index] = false
      }
      if (!this.$accountLevel.length) {
        this.state.loading = true
        let res
        res = await this.$http.get(this.url + '/users/account-levels')
        if (res[0]) {
          this.$accountLevel = res[0].data
        }
      }
      let index = this.$accountLevel.findIndex(i => i.name === res.data.baseDTO.userAccountLevel)
      this.level = this.$accountLevel[index]
      this.pervLevel = index ? this.$accountLevel[index - 1] : {tradeAmount: 0}
    },
  },
  created() {
    this.url = this.$urls.find(e => e[0] === this.$route.query.s)[1]
    this.getData()
  }
}
</script>

<style scoped>

</style>
